import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { TokenService } from '../shared/services/token.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sessionLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpService, private tokenService: TokenService) {}

  session(token) {
    // let params = {
    //   token: token,
    // };
    return this.http.post(`auth/session`, null);
  }
  setUser(value) {
    this._currentUser.next(value);
    sessionStorage.setItem('user', JSON.stringify(value));
  }
  getUser() {
    return this._currentUser.value;
  }
  isAuthenticated() {
    // console.log(
    //   ?.apps['senseware']?.enable
    // );
    const user = this.getUser() || JSON.parse(sessionStorage.getItem('user'));
    const enabledApp = environment?.settings?.ignoreSensewareAppEnabled
      ? true
      : user?.apps['senseware']?.enable;
    // use flag in environment to ignore this check for enabled app ignoreSensewareAppEnabled
    // already added to local environment for easier debugging
    return !!this.tokenService.getToken() && enabledApp;
  }
  logout() {
    this.tokenService.logout();
  }
}
