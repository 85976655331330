import { Component, OnInit } from '@angular/core';
import { DataService } from './shared/services/data.service';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'senseware-iot-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}
  title = 'senseware-iot';
  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.router.events.subscribe({
        next: (event) => {
          if (event instanceof NavigationError) {
            this.router.navigateByUrl('/monitoring');
          }
          if (event instanceof NavigationEnd) {
            if (event.url == '/') {
              this.router.navigateByUrl('/monitoring');
            }
          }
        },
      });
    }
  }
}
