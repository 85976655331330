import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
// import jwt_decode from 'jwt-decode';
interface UserData {
  Email: string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private accessToken = 'accessToken';
  private expiresIn = 'expiresIn';
  private user = 'profileCompleted';
  private tokenInfo = 'tokentInfo';
  // currentUser: BehaviorSubject<any> = new BehaviorSubject<any>({}) ;
  currentUser: any;
  isUserAuth: boolean;
  userModel = new BehaviorSubject<any>({});
  constructor(private router: Router) {
    const user = JSON.parse(localStorage.getItem('user'));
    this.currentUser = user;
  }

  /**
   * @param {string} token  The target to process
   * @returns Just save the token into cookies or session
   */
  setToken(token: string): void {
    sessionStorage.setItem(this.accessToken, token);
  }

  setUserLanguage(lang: string) {
    sessionStorage.setItem('lang', lang);
  }

  getUserLanguage() {
    const lang = localStorage.getItem('lang');
    return lang;
  }

  setUserId(id: any) {
    localStorage.setItem('userId', id);
  }

  getUserId() {
    const userId = localStorage.getItem('userId');
    return userId;
  }

  setUserRole(role) {
    localStorage.setItem('role', role);
  }
  setUser(user: any) {
    this.currentUser = user;
    localStorage.setItem('user', JSON.stringify(this.currentUser));
  }
  hasRole(role: string): boolean {
    return this.currentUser.roles.includes(role);
  }

  getUserRole() {
    const role = localStorage.getItem('role');
    return role;
  }

  /**
   * @returns the saved token
   */
  getToken(): string {
    let token = sessionStorage.getItem(this.accessToken);
    return token;
  }

  /**
   * @param {number} token  token expiration seconds
   */
  setTokenExpiration(tokenExpiration: number): void {
    localStorage.setItem(this.expiresIn, tokenExpiration.toString());
  }

  /**
   * @param {number} token  token expiration seconds
   * @returns if the token has been expired
   */
  checkIfTokenExpired(tokenExpiration: number): boolean {
    const today = new Date();
    const expiryDate = new Date(today.getTime() + tokenExpiration * 1000);
    if (today > expiryDate) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @returns clear all saved info
   */
  clear(): void {
    this.token = null;
    sessionStorage.clear();
  }

  /**
   * @returns logout the user from the system
   */
  logout(): void {
    this.clear();
    this.router.navigateByUrl('/auth/signin');
  }

  hasPermission(permission: string): boolean {
    // return this.currentUser.permissions.includes(permission);
    return false;
  }

  /**
   * @returns check the user is authenticated or not
   */
  isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    if (token != undefined || token != null) {
      this.isUserAuth = true;

      return true;
    } else {
      this.isUserAuth = false;

      return false;
    }
  }
}
