import { Settings } from '../app/shared/models/settings';

// The list of which env maps to which file can be found in `.angular-cli.json`.
const settings: Settings = {
  apiProtocol: 'https',
  apiHost: 'backend.avl.test.afaqy.pro', // develop
  jsonHost: 'http://localhost:4200',
  stompConfig: {
    url: 'https://stageavl.afaqy.sa:12163/socket',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    heartbeat_in: 0,
    heartbeat_out: 50000,
    reconnect_delay: 0,
    debug: true,
  },
  pagination: {
    limit: 1000,
  },
  WSStompUnitsURL: 'https://web-notifier.avl.dev.afaqy.pro/socket',
  geoSearchProviderUrl: 'https://nominatim.afaqy.pro/',
  AVL_Login_URL: 'https://web.avl.test.afaqy.pro/auth/login',
  intervalGap: 15,
  signalIntervalTimeout: 60,
  aggregatedSensorTypes: ['temperature', 'humidity', 'air_quality_co2'],
  directSensorTypes: ['in_battery_volt'],

  // apiPort:3000
};
export const environment = {
  production: false,
  settings,
};
